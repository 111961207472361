import React from 'react';

const AboutBlock = () => {
  return (
    <section className='main_page_about_section_wrapper'>
      <div className='main_page_about_section_content'>
        <div className='main_page_about_section_content_card_first'>
          <div className='main_page_about_section_content_card_row'>
            <svg
              className='main_page_about_section_content_card_camera'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M29.9487 7.79495H24.135L22.896 4.49099C22.7159 4.01058 22.2567 3.69238 21.7436 3.69238H10.2564C9.74334 3.69238 9.2841 4.01058 9.104 4.49099L7.86503 7.79495H2.05128C0.9184 7.79495 0 8.71335 0 9.84623V26.2565C0 27.3894 0.9184 28.3078 2.05128 28.3078H29.9487C31.0816 28.3078 32 27.3894 32 26.2565V9.84623C32 8.71335 31.0816 7.79495 29.9487 7.79495ZM29.5385 25.8462H2.46154V10.2565H9.57095L11.1093 6.15392H20.8907L22.4291 10.2565H29.5385V25.8462Z'
                fill='#50A398'
              />
              <path
                d='M16 11.4872C12.7198 11.4872 10.0513 14.1557 10.0513 17.4359C10.0513 20.7161 12.7198 23.3846 16 23.3846C19.2802 23.3846 21.9487 20.7161 21.9487 17.4359C21.9487 14.1557 19.2802 11.4872 16 11.4872ZM16 20.6391C14.2338 20.6391 12.7969 19.2021 12.7969 17.4359C12.7969 15.6697 14.2338 14.2327 16 14.2327C17.7661 14.2327 19.2031 15.6697 19.2031 17.4359C19.2031 19.2021 17.7661 20.6391 16 20.6391Z'
                fill='#50A398'
              />
            </svg>
            <h1 className='main_page_about_section_content_card_title'>Фотодел</h1>
          </div>
          <p className='main_page_about_section_content_card_text__1'>
            Cервис удобного и быстрого поиска профессионалов и удачных мест для съемок!
          </p>
          <p className='main_page_about_section_content_card_text__2_first'>
            Вам потребуется совершить ВСЕГО три простых шага!
          </p>
        </div>

        <div className='main_page_about_section_content_card'>
          <svg
            className='main_page_about_section_content_card_image'
            width='200'
            height='158'
            viewBox='0 0 200 158'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_59_60)'>
              <path d='M181.566 0.102905H0.0775146V116.336H181.566V0.102905Z' fill='#E6E6E6' />
              <path d='M176.377 14.6815H5.26709V109.46H176.377V14.6815Z' fill='white' />
              <path d='M181.489 0H0V7.71032H181.489V0Z' fill='#50A398' />
              <path
                d='M5.72971 5.37091C6.51895 5.37091 7.15876 4.73111 7.15876 3.94186C7.15876 3.15262 6.51895 2.51282 5.72971 2.51282C4.94047 2.51282 4.30066 3.15262 4.30066 3.94186C4.30066 4.73111 4.94047 5.37091 5.72971 5.37091Z'
                fill='white'
              />
              <path
                d='M11.154 5.37091C11.9433 5.37091 12.5831 4.73111 12.5831 3.94186C12.5831 3.15262 11.9433 2.51282 11.154 2.51282C10.3648 2.51282 9.72498 3.15262 9.72498 3.94186C9.72498 4.73111 10.3648 5.37091 11.154 5.37091Z'
                fill='white'
              />
              <path
                d='M16.5783 5.37091C17.3676 5.37091 18.0074 4.73111 18.0074 3.94186C18.0074 3.15262 17.3676 2.51282 16.5783 2.51282C15.7891 2.51282 15.1493 3.15262 15.1493 3.94186C15.1493 4.73111 15.7891 5.37091 16.5783 5.37091Z'
                fill='white'
              />
              <path d='M64.782 24.5155H20.8148V99.626H64.782V24.5155Z' fill='#E6E6E6' />
              <path d='M109.011 24.5155H72.3715V45.1905H109.011V24.5155Z' fill='#50A398' />
              <path d='M109.011 50.9481H72.3715V73.1934H109.011V50.9481Z' fill='#E6E6E6' />
              <path d='M109.011 78.951H72.3715V99.6261H109.011V78.951Z' fill='#E6E6E6' />
              <path d='M160.829 24.5155H116.862V57.2482H160.829V24.5155Z' fill='#E6E6E6' />
              <path d='M160.829 66.8933H116.862V99.626H160.829V66.8933Z' fill='#E6E6E6' />
              <path
                d='M143.891 59.5998V52.5529C143.891 50.0485 144.886 47.6467 146.657 45.8758C148.428 44.105 150.83 43.1101 153.334 43.1101C155.839 43.1101 158.241 44.105 160.011 45.8758C161.782 47.6467 162.777 50.0485 162.777 52.5529V59.5998C162.777 59.9361 162.643 60.2585 162.405 60.4963C162.167 60.7341 161.845 60.8679 161.509 60.8683H145.16C144.824 60.8679 144.501 60.7341 144.263 60.4963C144.026 60.2585 143.892 59.9361 143.891 59.5998Z'
                fill='#202424'
              />
              <path
                d='M167.297 151.307L163.967 152.229L158.826 139.821L163.742 138.461L167.297 151.307Z'
                fill='#FFB8B8'
              />
              <path
                d='M161.324 151.935L167.747 150.158L168.867 154.202L158.399 157.099C158.252 156.568 158.211 156.013 158.279 155.466C158.346 154.919 158.521 154.391 158.792 153.911C159.064 153.432 159.427 153.011 159.862 152.672C160.296 152.333 160.793 152.082 161.324 151.935V151.935Z'
                fill='#202424'
              />
              <path
                d='M132.574 153.869H129.118L127.474 140.54H132.575L132.574 153.869Z'
                fill='#FFB8B8'
              />
              <path
                d='M126.65 152.881H133.314V157.078H122.453C122.453 156.527 122.562 155.981 122.773 155.472C122.984 154.963 123.293 154.5 123.683 154.11C124.072 153.721 124.535 153.412 125.044 153.201C125.553 152.99 126.099 152.881 126.65 152.881V152.881Z'
                fill='#202424'
              />
              <path
                d='M128.407 79.3756C128.654 79.7086 128.97 79.9834 129.335 80.1807C129.699 80.3781 130.102 80.4931 130.516 80.5178C130.93 80.5424 131.344 80.4761 131.729 80.3234C132.114 80.1708 132.461 79.9355 132.746 79.6342L141.932 83.7659L141.096 78.5987L132.501 75.4673C131.963 75.023 131.281 74.7914 130.584 74.8164C129.886 74.8414 129.223 75.1213 128.718 75.6031C128.213 76.0848 127.903 76.7349 127.846 77.4302C127.788 78.1255 127.988 78.8177 128.407 79.3756H128.407Z'
                fill='#FFB8B8'
              />
              <path
                d='M147.494 110.449C144.608 110.449 141.62 110.019 139.108 108.74C137.775 108.075 136.594 107.142 135.638 105.999C134.682 104.856 133.972 103.528 133.553 102.098C132.244 97.9546 133.894 93.884 135.49 89.9477C136.479 87.5084 137.412 85.2045 137.655 82.9335L137.739 82.1273C138.117 78.5058 138.443 75.3782 140.247 74.2052C141.183 73.5971 142.44 73.5624 144.092 74.0989L159.608 79.1392L159.038 108.592L158.943 108.624C158.862 108.651 153.392 110.449 147.494 110.449Z'
                fill='#202424'
              />
              <path
                d='M143.821 64.9296C143.821 64.9296 151.432 62.6746 157.351 63.5202C157.351 63.5202 153.968 82.124 155.096 88.3253C156.223 94.5265 135.506 90.7212 139.734 84.8018L141.143 77.7549C141.143 77.7549 138.324 74.9362 140.861 71.5536L143.821 64.9296Z'
                fill='#ADFAF2'
              />
              <path
                d='M140.078 109.891L130.698 108.132L133.973 82.9304C134.193 82.2268 139.266 66.2594 141.57 65.3955C143.242 64.8064 144.953 64.3311 146.69 63.9729L147.024 63.9061L145.143 66.7274L137.655 84.67L140.078 109.891Z'
                fill='#202424'
              />
              <path
                d='M135.19 150.78L123.071 148.761L129.872 120.989L139.993 83.01L140.092 83.6844C140.1 83.7347 141.046 88.6961 155.074 86.4946L155.198 86.4754L155.232 86.5955L172.187 146.505L158.378 148.806L145.783 105.097L135.19 150.78Z'
                fill='#202424'
              />
              <path
                d='M151.988 114.607L151.995 114.394C152.004 114.157 152.838 90.611 152.559 81.1415C152.28 71.6398 155.356 63.4113 155.387 63.3293L155.412 63.2632L155.48 63.2437C159.46 62.1067 162.913 65.5002 162.947 65.5346L162.996 65.583L161.862 74.9346L166.715 108.941L151.988 114.607Z'
                fill='#202424'
              />
              <path
                d='M151.394 60.7042C155.217 60.7042 158.317 57.6046 158.317 53.7811C158.317 49.9575 155.217 46.8579 151.394 46.8579C147.57 46.8579 144.471 49.9575 144.471 53.7811C144.471 57.6046 147.57 60.7042 151.394 60.7042Z'
                fill='#FFB8B8'
              />
              <path
                d='M141.658 53.1167C141.66 51.1363 142.448 49.2376 143.848 47.8373C145.248 46.4369 147.147 45.6492 149.127 45.647H150.537C152.517 45.6492 154.416 46.4369 155.816 47.8373C157.216 49.2376 158.004 51.1363 158.006 53.1167V53.2576H155.028L154.012 50.413L153.809 53.2576H152.27L151.757 51.8224L151.654 53.2576H141.658V53.1167Z'
                fill='#202424'
              />
              <path
                d='M150.713 61.6606C150.573 61.4713 150.489 61.2463 150.472 61.0115C150.454 60.7768 150.503 60.5419 150.613 60.3338C152.107 57.493 154.198 52.2439 151.422 49.0068L151.223 48.7742H159.281V60.8722L151.961 62.1638C151.887 62.1769 151.811 62.1835 151.736 62.1836C151.536 62.1836 151.339 62.1362 151.161 62.0453C150.983 61.9544 150.83 61.8225 150.713 61.6606Z'
                fill='#202424'
              />
              <path
                d='M118.269 11.7109C112.631 6.9529 105.731 3.93641 98.409 3.02846C91.0872 2.12051 83.6594 3.36025 77.0294 6.59681C70.3993 9.83338 64.853 14.9272 61.0652 21.2585C57.2774 27.5898 55.4115 34.8855 55.6945 42.2579C55.9776 49.6303 58.3973 56.7616 62.6594 62.7838C66.9214 68.8061 72.842 73.4597 79.7006 76.1784C86.5593 78.8972 94.0603 79.5638 101.291 78.0972C108.521 76.6305 115.17 73.0939 120.427 67.9177L167.111 107.309C167.821 107.91 168.741 108.203 169.668 108.126C170.595 108.048 171.453 107.606 172.053 106.895C172.654 106.185 172.948 105.265 172.87 104.338C172.792 103.411 172.35 102.553 171.639 101.953L171.634 101.948L124.951 62.5569C130.374 54.7734 132.695 45.2453 131.459 35.8397C130.223 26.4341 125.519 17.8291 118.269 11.7109V11.7109ZM115.26 58.7637C111.623 63.0739 106.79 66.2087 101.371 67.772C95.9526 69.3352 90.1922 69.2565 84.8184 67.5459C79.4446 65.8353 74.6987 62.5695 71.1809 58.1617C67.6631 53.7538 65.5314 48.4018 65.0553 42.7824C64.5792 37.1631 65.7802 31.5287 68.5063 26.5919C71.2325 21.655 75.3613 17.6374 80.3707 15.0472C85.3802 12.4569 91.0453 11.4102 96.6495 12.0396C102.254 12.669 107.546 14.9461 111.856 18.5829H111.856C114.718 20.9977 117.076 23.9526 118.796 27.2787C120.516 30.6048 121.563 34.2372 121.88 37.9683C122.196 41.6994 121.774 45.4563 120.638 49.0244C119.502 52.5925 117.675 55.9019 115.26 58.7637V58.7637Z'
                fill='#202424'
              />
              <path
                opacity='0.3'
                d='M75.0792 62.1679C69.6091 57.5519 66.075 51.0475 65.1786 43.9463C64.2822 36.8451 66.0892 29.6665 70.2406 23.8359C69.6954 24.385 69.1684 24.96 68.6594 25.561C66.2447 28.4228 64.4172 31.7322 63.2814 35.3003C62.1456 38.8684 61.7237 42.6253 62.0398 46.3564C62.3559 50.0875 63.4038 53.7199 65.1237 57.046C66.8435 60.3722 69.2017 63.327 72.0635 65.7418C74.9254 68.1566 78.2348 69.984 81.8029 71.1199C85.3709 72.2557 89.1278 72.6776 92.8589 72.3615C96.5901 72.0454 100.222 70.9975 103.549 69.2776C106.875 67.5577 109.83 65.1995 112.244 62.3377C112.752 61.736 113.23 61.1197 113.679 60.4889C108.63 65.562 101.857 68.5509 94.7067 68.8619C87.5559 69.173 80.5497 66.7835 75.0792 62.1679V62.1679Z'
                fill='#202424'
              />
              <path
                d='M153.617 91.4787C154.019 91.5772 154.438 91.5861 154.845 91.505C155.251 91.4239 155.635 91.2546 155.969 91.0092C156.303 90.7638 156.579 90.4481 156.777 90.0845C156.976 89.7208 157.092 89.3181 157.119 88.9045L166.824 86.2105L162.879 82.7706L154.267 85.8563C153.569 85.8583 152.897 86.1162 152.377 86.5811C151.856 87.046 151.525 87.6855 151.445 88.3786C151.364 89.0716 151.541 89.77 151.942 90.3414C152.342 90.9128 152.938 91.3175 153.617 91.4787V91.4787Z'
                fill='#FFB8B8'
              />
              <path
                d='M157.651 90.0528L157.06 84.4417L165.168 79.9686L159.882 73.57L160.744 66.3823L162.892 65.4618L162.959 65.5477C163.972 66.85 172.854 78.3112 172.854 79.728C172.854 81.1832 171.155 85.4567 168.83 86.6191C166.588 87.7404 158.166 89.9197 157.809 90.0121L157.651 90.0528Z'
                fill='#202424'
              />
              <path
                d='M199.718 157.454H92.3235C92.2487 157.454 92.177 157.424 92.1242 157.371C92.0713 157.319 92.0416 157.247 92.0416 157.172C92.0416 157.097 92.0713 157.026 92.1242 156.973C92.177 156.92 92.2487 156.89 92.3235 156.89H199.718C199.793 156.89 199.865 156.92 199.917 156.973C199.97 157.026 200 157.097 200 157.172C200 157.247 199.97 157.319 199.917 157.371C199.865 157.424 199.793 157.454 199.718 157.454Z'
                fill='#CCCCCC'
              />
            </g>
            <defs>
              <clipPath id='clip0_59_60'>
                <rect width='200' height='157.454' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <p className='main_page_about_section_content_card_text__2'>
            Задать необходимые параметры поиска в нашей базе
          </p>
        </div>

        <div className='main_page_about_section_content_card'>
          <svg
            className='main_page_about_section_content_card_img'
            width='200'
            height='187'
            viewBox='0 0 200 187'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_59_1)'>
              <path
                d='M152.402 44.3591H151.376V16.2619C151.376 11.949 149.661 7.81272 146.609 4.76301C143.558 1.71331 139.419 0 135.103 0H75.5363C71.2205 0 67.0814 1.71331 64.0297 4.76301C60.9779 7.81272 59.2634 11.949 59.2634 16.2619V170.405C59.2634 174.718 60.9779 178.854 64.0297 181.904C67.0814 184.953 71.2205 186.667 75.5363 186.667H135.104C139.42 186.667 143.559 184.953 146.61 181.904C149.662 178.854 151.377 174.718 151.377 170.405V64.359H152.402V44.3591Z'
                fill='#202424'
              />
              <path
                d='M148.059 15.1738V171.494C148.058 174.764 146.759 177.9 144.445 180.212C142.132 182.525 138.994 183.824 135.722 183.825H74.9301C71.6565 183.826 68.5169 182.527 66.2018 180.214C63.8867 177.901 62.5857 174.764 62.5851 171.492V15.1738C62.5861 11.9027 63.8872 8.76592 66.2023 6.45338C68.5174 4.14085 71.6568 2.84192 74.9301 2.84228H82.3043C81.9422 3.73213 81.8044 4.69724 81.903 5.65277C82.0016 6.6083 82.3336 7.525 82.8697 8.3223C83.4058 9.1196 84.1298 9.77308 84.9778 10.2253C85.8259 10.6776 86.7721 10.9147 87.7334 10.9159H122.391C123.352 10.9146 124.299 10.6773 125.147 10.2249C125.995 9.77258 126.718 9.11896 127.254 8.32154C127.791 7.52412 128.122 6.60732 128.221 5.65172C128.319 4.69613 128.181 3.731 127.819 2.84119H135.718C138.99 2.84177 142.128 4.14082 144.442 6.45277C146.756 8.76472 148.057 11.9003 148.058 15.1702L148.059 15.1738Z'
                fill='white'
              />
              <path
                d='M141.582 175.666H69.0624C68.4434 175.665 67.8499 175.419 67.4122 174.981C66.9745 174.544 66.7282 173.951 66.7275 173.332V20.9796C66.7282 20.3609 66.9745 19.7679 67.4122 19.3304C67.8499 18.893 68.4434 18.6469 69.0624 18.6462H141.582C142.201 18.6469 142.794 18.893 143.232 19.3304C143.67 19.7679 143.916 20.3609 143.917 20.9796V173.332C143.916 173.951 143.67 174.544 143.232 174.981C142.794 175.419 142.201 175.665 141.582 175.666Z'
                fill='white'
              />
              <path
                d='M105.047 70.0841C116.082 70.0841 125.027 61.1447 125.027 50.1173C125.027 39.09 116.082 30.1506 105.047 30.1506C94.0126 30.1506 85.0671 39.09 85.0671 50.1173C85.0671 61.1447 94.0126 70.0841 105.047 70.0841Z'
                fill='#D2D9D8'
              />
              <path
                d='M104.773 67.381C114.314 67.381 122.048 59.6518 122.048 50.1174C122.048 40.5829 114.314 32.8538 104.773 32.8538C95.2318 32.8538 87.4974 40.5829 87.4974 50.1174C87.4974 59.6518 95.2318 67.381 104.773 67.381Z'
                fill='white'
              />
              <path
                d='M105.157 40.3257C103.209 40.3257 101.305 40.903 99.6851 41.9845C98.0654 43.066 96.803 44.6033 96.0575 46.4018C95.312 48.2003 95.117 50.1794 95.497 52.0887C95.877 53.998 96.8151 55.7519 98.1926 57.1284C99.5701 58.5049 101.325 59.4424 103.236 59.8222C105.146 60.202 107.127 60.007 108.926 59.2621C110.726 58.5171 112.264 57.2555 113.347 55.6369C114.429 54.0182 115.007 52.1152 115.007 50.1685C115.007 47.558 113.969 45.0545 112.122 43.2086C110.275 41.3627 107.769 40.3257 105.157 40.3257ZM105.157 43.2786C105.742 43.2786 106.313 43.4518 106.799 43.7762C107.285 44.1007 107.664 44.5619 107.887 45.1014C108.111 45.641 108.169 46.2348 108.055 46.8076C107.941 47.3804 107.66 47.9065 107.247 48.3195C106.833 48.7325 106.307 49.0137 105.734 49.1276C105.16 49.2416 104.566 49.1831 104.026 48.9596C103.486 48.7361 103.025 48.3576 102.7 47.872C102.376 47.3864 102.202 46.8155 102.202 46.2315C102.202 45.8437 102.279 45.4597 102.427 45.1014C102.576 44.7432 102.793 44.4177 103.068 44.1435C103.342 43.8693 103.668 43.6517 104.026 43.5034C104.385 43.355 104.769 43.2786 105.157 43.2786ZM105.157 57.4938C103.99 57.4885 102.842 57.1986 101.812 56.6494C100.782 56.1002 99.9016 55.3082 99.2474 54.3421C99.2947 52.3736 103.187 51.2899 105.157 51.2899C107.127 51.2899 111.019 52.3736 111.067 54.3421C110.412 55.3076 109.532 56.0993 108.502 56.6485C107.472 57.1978 106.324 57.4882 105.157 57.4946V57.4938Z'
                fill='#50A398'
              />
              <path d='M121.537 76.7939H86.9697V79.3903H121.537V76.7939Z' fill='#D2D9D8' />
              <path d='M125.848 83.5405H82.3447V86.1368H125.848V83.5405Z' fill='#D2D9D8' />
              <path d='M135.963 102.92H74.6807V164.161H135.963V102.92Z' fill='#D2D9D8' />
              <path d='M134.345 104.215H75.655V162.866H134.345V104.215Z' fill='white' />
              <path d='M110.587 109.877H87.7416V111.593H110.587V109.877Z' fill='#D2D9D8' />
              <path d='M123.02 114.915H87.7416V116.631H123.02V114.915Z' fill='#50A398' />
              <path d='M116.86 119.712H88.1083V121.428H116.86V119.712Z' fill='#D2D9D8' />
              <path d='M104.526 124.976H87.7416V126.692H104.526V124.976Z' fill='#D2D9D8' />
              <path d='M113.074 130.118H87.7416V131.834H113.074V130.118Z' fill='#50A398' />
              <path d='M110.587 135.292H87.7416V137.008H110.587V135.292Z' fill='#D2D9D8' />
              <path d='M123.02 140.33H87.7416V142.046H123.02V140.33Z' fill='#50A398' />
              <path d='M116.86 145.127H88.1083V146.843H116.86V145.127Z' fill='#D2D9D8' />
              <path d='M104.526 150.391H87.7416V152.107H104.526V150.391Z' fill='#D2D9D8' />
              <path d='M113.074 155.533H87.7416V157.249H113.074V155.533Z' fill='#D2D9D8' />
              <path
                d='M190.3 133.144C190.136 133.43 189.918 133.681 189.657 133.882C189.397 134.083 189.099 134.232 188.782 134.318C188.464 134.405 188.132 134.428 187.805 134.386C187.479 134.345 187.163 134.239 186.877 134.076C186.592 133.912 186.341 133.694 186.139 133.434C185.937 133.174 185.789 132.876 185.703 132.559C185.616 132.241 185.593 131.91 185.634 131.583C185.676 131.257 185.782 130.942 185.945 130.656C185.951 130.646 185.956 130.637 185.962 130.627C186.032 130.509 186.111 130.397 186.198 130.292L184.089 115.265L184.026 114.815L183.597 111.749L183.534 111.307L183.767 111.318L187.855 111.505L188.951 111.554L189.088 114.351L189.866 130.078C190.272 130.466 190.535 130.98 190.614 131.536C190.693 132.092 190.582 132.658 190.3 133.144Z'
                fill='#FFB8B8'
              />
              <path
                d='M184.038 114.951L189.228 114.476C189.497 114.45 189.745 114.319 189.918 114.112C190.092 113.904 190.176 113.637 190.152 113.367L189.191 102.581C189.145 102.067 188.998 101.567 188.758 101.109C188.519 100.651 188.192 100.245 187.795 99.914C187.399 99.5827 186.941 99.3328 186.448 99.1783C185.955 99.0239 185.436 98.9681 184.921 99.014C184.406 99.0599 183.906 99.2067 183.448 99.446C182.99 99.6853 182.583 100.012 182.252 100.409C181.92 100.805 181.67 101.262 181.516 101.755C181.361 102.248 181.305 102.766 181.351 103.281C181.358 103.355 181.367 103.429 181.378 103.503L182.934 114.082C182.97 114.325 183.091 114.546 183.277 114.707C183.462 114.867 183.699 114.955 183.944 114.956C183.975 114.956 184.007 114.955 184.038 114.951Z'
                fill='#ADFAF2'
              />
              <path
                d='M187.972 106.238C188.497 104.752 188.521 103.135 188.041 101.634C187.561 100.133 186.603 98.8297 185.313 97.9233C183.356 96.585 180.678 96.1046 177.793 99.536C172.025 106.399 170.514 113.948 170.514 113.948L182.326 115.869C182.326 115.869 186.343 110.962 187.972 106.238Z'
                fill='#ADFAF2'
              />
              <path
                d='M168.868 183.541H165.5L163.898 170.56H168.868L168.868 183.541Z'
                fill='#FFB8B8'
              />
              <path
                d='M159.006 186.666H169.59V182.579H163.095C162.011 182.579 160.971 183.01 160.204 183.776C159.437 184.542 159.006 185.582 159.006 186.666Z'
                fill='#202424'
              />
              <path
                d='M196.555 180.606L193.358 181.662L187.76 169.839L192.479 168.281L196.555 180.606Z'
                fill='#FFB8B8'
              />
              <path
                d='M188.172 186.666L198.222 183.346L196.938 179.466L190.772 181.503C190.262 181.672 189.79 181.939 189.384 182.289C188.977 182.64 188.643 183.067 188.402 183.546C188.161 184.026 188.016 184.548 187.977 185.083C187.937 185.618 188.004 186.156 188.172 186.666Z'
                fill='#202424'
              />
              <path
                d='M180.557 94.5196C184.283 94.5196 187.304 91.501 187.304 87.7774C187.304 84.0538 184.283 81.0352 180.557 81.0352C176.831 81.0352 173.81 84.0538 173.81 87.7774C173.81 91.501 176.831 94.5196 180.557 94.5196Z'
                fill='#FFB8B8'
              />
              <path
                d='M174.65 179.928L177.777 159.3C177.81 159.08 177.919 158.878 178.085 158.729C178.251 158.58 178.463 158.494 178.686 158.484C178.908 158.474 179.127 158.542 179.305 158.676C179.483 158.81 179.61 159.001 179.662 159.217L184.425 178.876C184.5 179.177 184.685 179.439 184.943 179.611C185.202 179.783 185.515 179.852 185.822 179.805L198.96 177.695C199.134 177.667 199.3 177.602 199.447 177.505C199.594 177.407 199.718 177.279 199.812 177.13C199.905 176.98 199.966 176.812 199.989 176.638C200.012 176.463 199.998 176.285 199.947 176.116L182.816 119.677C182.768 119.517 182.762 119.347 182.8 119.183L183.37 116.713C183.432 116.445 183.404 116.164 183.288 115.914C183.172 115.665 182.976 115.461 182.731 115.336C180.398 114.147 174.49 111.674 170.031 114.383C169.886 114.471 169.761 114.588 169.663 114.726C169.565 114.864 169.496 115.02 169.46 115.186L156.078 178.041C156.042 178.211 156.042 178.388 156.079 178.559C156.116 178.73 156.189 178.891 156.293 179.031C156.396 179.172 156.529 179.289 156.681 179.375C156.834 179.46 157.003 179.512 157.177 179.528L173.317 180.973C173.354 180.976 173.39 180.977 173.427 180.977C173.723 180.977 174.008 180.871 174.232 180.678C174.456 180.486 174.604 180.219 174.649 179.927L174.65 179.928Z'
                fill='#202424'
              />
              <path
                d='M185.031 83.0138C184.522 82.7081 184.071 82.3161 183.697 81.8554C183.512 81.6226 183.391 81.3463 183.344 81.053C183.298 80.7597 183.327 80.4594 183.431 80.1809C183.771 79.4293 184.784 79.1927 185.569 79.4477C186.354 79.7027 186.961 80.3184 187.534 80.9116C188.039 81.4332 188.559 81.98 188.798 82.666C189.037 83.352 188.914 84.2219 188.318 84.6384C187.733 85.0468 186.892 84.8797 186.329 84.441C185.767 84.0023 185.419 83.3512 185.086 82.7198L185.031 83.0138Z'
                fill='#202424'
              />
              <path
                d='M187.316 81.0928C187.353 79.1932 189.52 77.8308 191.392 78.1676C193.263 78.5044 194.701 80.0988 195.421 81.8573C196.943 85.5728 195.801 89.8123 194.335 93.5514C192.869 97.2905 191.055 101.084 191.269 105.093C191.414 107.259 192.141 109.347 193.375 111.135C194.609 112.922 196.303 114.344 198.278 115.249C196.166 116.206 193.642 115.218 191.867 113.726C187.925 110.414 186.404 104.518 188.252 99.7144C189.163 97.3451 190.769 95.3181 192.022 93.1108C193.276 90.9035 194.196 88.3019 193.548 85.8476C192.901 83.3932 190.146 81.4063 187.791 82.3558L187.316 81.0928Z'
                fill='#202424'
              />
              <path
                d='M180.152 90.7692C180.74 89.776 181.334 88.4721 180.259 87.6038C180.025 87.4236 179.758 87.292 179.472 87.2165C179.187 87.1411 178.889 87.1234 178.597 87.1646C177.385 87.3106 176.065 87.3707 174.892 86.9307C174.452 86.7794 174.047 86.5413 173.701 86.2305C173.355 85.9197 173.074 85.5425 172.877 85.1214C172.273 83.6874 173.03 81.9448 174.296 81.0384C175.07 80.504 175.986 80.21 176.927 80.1934C177.868 80.1767 178.793 80.4381 179.586 80.9448C181.226 80.2209 183.215 80.5429 184.705 81.5394C186.195 82.5358 187.215 84.1315 187.769 85.8354C188.279 87.302 188.367 88.8823 188.022 90.3964C187.745 91.4497 187.218 92.4207 186.485 93.2272C185.753 94.0338 184.837 94.6521 183.815 95.0298C183.417 95.1868 182.984 95.2364 182.561 95.1738C182.138 95.1111 181.738 94.9384 181.402 94.673C181.402 94.673 180.473 93.8618 180.182 92.8848C179.958 92.1335 179.838 91.2998 180.152 90.7692Z'
                fill='#202424'
              />
              <path
                d='M160.138 96.7854C160.171 96.9335 160.193 97.084 160.203 97.2356L172.003 104.038L174.871 102.388L177.929 106.389L173.136 109.803C172.748 110.079 172.282 110.222 171.806 110.21C171.331 110.199 170.872 110.033 170.499 109.739L158.33 100.146C157.78 100.349 157.182 100.38 156.614 100.236C156.047 100.091 155.537 99.7768 155.152 99.3353C154.768 98.8938 154.527 98.3457 154.461 97.7639C154.396 97.1822 154.51 96.5944 154.787 96.0787C155.064 95.563 155.492 95.1439 156.013 94.8771C156.535 94.6104 157.125 94.5086 157.706 94.5853C158.286 94.662 158.83 94.9136 159.264 95.3066C159.698 95.6996 160.002 96.2154 160.136 96.7854H160.138Z'
                fill='#FFB8B8'
              />
              <path
                d='M172.398 104.361L176.212 109.3C176.32 109.441 176.458 109.556 176.615 109.639C176.772 109.721 176.945 109.769 177.122 109.778C177.3 109.788 177.477 109.76 177.642 109.695C177.807 109.63 177.957 109.53 178.08 109.403L182.397 104.922C183.128 104.379 183.612 103.568 183.745 102.668C183.877 101.767 183.645 100.851 183.102 100.121C182.558 99.391 181.746 98.9067 180.845 98.7746C179.944 98.6426 179.028 98.8736 178.297 99.4169C178.26 99.4447 178.223 99.4732 178.187 99.5025L172.772 102.528C172.617 102.615 172.483 102.734 172.378 102.877C172.273 103.02 172.2 103.185 172.165 103.359C172.129 103.533 172.132 103.712 172.172 103.885C172.212 104.058 172.289 104.22 172.397 104.361L172.398 104.361Z'
                fill='#ADFAF2'
              />
              <path
                d='M51.1258 114.378C51.01 114.471 50.9017 114.572 50.8022 114.682L37.5749 112.572L36.3617 109.532L31.5784 110.697L33.6651 117.844L50.484 118.069C50.5446 118.17 50.6113 118.268 50.6838 118.361C51.0335 118.79 51.5024 119.107 52.0316 119.271C52.5608 119.435 53.1267 119.438 53.6582 119.282C54.1897 119.125 54.6631 118.815 55.019 118.391C55.3749 117.966 55.5974 117.446 55.6585 116.896C55.7197 116.346 55.6168 115.79 55.3627 115.298C55.1086 114.806 54.7147 114.4 54.2305 114.131C53.7463 113.862 53.1933 113.742 52.641 113.786C52.0887 113.83 51.5613 114.036 51.1258 114.378Z'
                fill='#FFB8B8'
              />
              <path
                d='M33.1188 183.541H36.4866L38.0886 170.56H33.1183L33.1188 183.541Z'
                fill='#A0616A'
              />
              <path
                d='M38.8921 182.58H32.3972V186.665H42.9815C42.9815 185.581 42.5506 184.542 41.7837 183.775C41.0168 183.009 39.9767 182.578 38.8921 182.578H38.8921L38.8921 182.58Z'
                fill='#202424'
              />
              <path
                d='M20.0214 182.591L23.3726 182.92L26.2407 170.159L21.2943 169.673L20.0214 182.591Z'
                fill='#A0616A'
              />
              <path
                d='M25.8632 182.2L19.4008 181.564L19 185.63L29.531 186.666C29.5837 186.132 29.5305 185.593 29.3747 185.08C29.2188 184.566 28.9633 184.089 28.6227 183.674C28.2821 183.259 27.863 182.915 27.3895 182.663C26.916 182.41 26.3972 182.252 25.8629 182.2L25.8632 182.2Z'
                fill='#202424'
              />
              <path
                d='M32.1813 94.0826C35.9075 94.0826 38.9281 91.064 38.9281 87.3404C38.9281 83.6167 35.9075 80.5981 32.1813 80.5981C28.4552 80.5981 25.4346 83.6167 25.4346 87.3404C25.4346 91.064 28.4552 94.0826 32.1813 94.0826Z'
                fill='#FFB8B8'
              />
              <path
                d='M37.2393 180.916C37.5288 180.917 37.8092 180.816 38.0311 180.63C38.253 180.444 38.4021 180.186 38.452 179.901C40.1939 170.233 45.8929 138.614 46.0292 137.738C46.0323 137.718 46.0337 137.697 46.0336 137.677V135.32C46.0338 135.234 46.007 135.15 45.9569 135.08L45.2043 134.027C45.1685 133.976 45.1216 133.934 45.0671 133.904C45.0126 133.875 44.9521 133.858 44.89 133.855C40.5988 133.654 26.5448 133.065 25.8658 133.912C25.184 134.763 25.4263 137.346 25.4812 137.854L25.4785 137.907L19.1646 178.26C19.1152 178.582 19.1945 178.91 19.3855 179.174C19.5765 179.438 19.8638 179.616 20.1851 179.67L24.1286 180.319C24.4223 180.366 24.7232 180.306 24.9765 180.151C25.2298 179.995 25.4188 179.753 25.509 179.47C26.7286 175.575 30.8185 162.473 32.236 157.403C32.245 157.371 32.2654 157.343 32.2932 157.325C32.321 157.307 32.3545 157.3 32.3874 157.305C32.4202 157.309 32.4503 157.326 32.472 157.351C32.4937 157.376 32.5056 157.408 32.5054 157.441C32.4346 162.274 32.2634 174.604 32.2033 178.863L32.197 179.322C32.1943 179.632 32.3084 179.932 32.5166 180.163C32.7248 180.394 33.0119 180.538 33.3213 180.567L37.1239 180.911C37.1622 180.915 37.2007 180.917 37.2393 180.916Z'
                fill='#202424'
              />
              <path
                d='M36.4633 96.7339C37.6406 97.4333 38.3452 98.7186 38.7496 100.028C39.498 102.449 39.9486 104.953 40.0912 107.483L40.5186 115.06L45.8078 135.146C41.2235 139.022 38.5793 138.141 32.408 134.97C26.2367 131.8 25.5318 136.027 25.5318 136.027L24.2979 118.937L22.5349 100.262C22.9307 99.7854 23.3787 99.355 23.8705 98.9785C25.5 97.712 27.396 96.8315 29.4155 96.4036C31.435 95.9756 33.5254 96.0113 35.5291 96.5079L36.4633 96.7339Z'
                fill='#D2D9D8'
              />
              <path
                d='M38.1812 120.19C38.039 120.243 37.9013 120.307 37.7692 120.382L25.5865 114.288L25.3843 110.986L20.359 110.64L20.0898 118.165L36.3699 123.546C36.5027 124.116 36.8061 124.632 37.2397 125.026C37.6732 125.419 38.2165 125.672 38.7971 125.749C39.3776 125.827 39.9681 125.726 40.4898 125.46C41.0116 125.194 41.4399 124.775 41.7179 124.26C41.9958 123.744 42.1101 123.157 42.0457 122.575C41.9812 121.993 41.741 121.445 41.357 121.003C40.973 120.561 40.4635 120.246 39.8961 120.1C39.3288 119.955 38.7305 119.985 38.1809 120.188L38.1812 120.19Z'
                fill='#FFB8B8'
              />
              <path
                d='M22.6227 100.346C19.6255 101.403 19.0973 112.856 19.0973 112.856C22.6235 110.918 26.8549 114.089 26.8549 114.089C26.8549 114.089 27.7364 111.094 28.7943 107.218C29.1137 106.128 29.1519 104.974 28.9053 103.865C28.6588 102.756 28.1354 101.728 27.384 100.875C27.384 100.875 25.6202 99.2878 22.6227 100.346Z'
                fill='#D2D9D8'
              />
              <path
                d='M26.133 89.1237C26.9736 88.4517 28.1205 89.6727 28.1205 89.6727L28.7929 83.625C28.7929 83.625 32.9957 84.1281 35.6861 83.457C38.3764 82.7858 38.7965 85.8932 38.7965 85.8932C38.9351 84.638 38.9633 83.3731 38.8805 82.113C38.7124 80.601 36.5269 79.0887 32.6601 78.0807C28.7932 77.0727 26.7758 81.441 26.7758 81.441C24.0844 82.7847 25.2914 89.7957 26.133 89.1237Z'
                fill='#202424'
              />
            </g>
            <defs>
              <clipPath id='clip0_59_1'>
                <rect width='200' height='186.667' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <p className='main_page_about_section_content_card_text__2'>
            Выбрать специалиста или место даже без регистрации
          </p>
        </div>

        <div className='main_page_about_section_content_card'>
          <svg
            className='main_page_about_section_content_card_image'
            width='200'
            height='145'
            viewBox='0 0 200 145'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_59_94)'>
              <path
                opacity='0.2'
                d='M51.4849 84.279C51.4849 84.279 51.9046 92.6729 33.9976 103.025C33.9976 103.025 33.9763 103.071 165.082 103.025C165.082 103.025 150.113 92.3931 149.134 80.5017'
                fill='#202424'
              />
              <path
                d='M148.579 78.2782V7.74366C149.424 7.52506 150.171 7.03231 150.705 6.34279C151.239 5.65326 151.528 4.80599 151.528 3.93402C151.528 3.41739 151.427 2.90583 151.229 2.42853C151.031 1.95124 150.742 1.51755 150.376 1.15224C150.011 0.786934 149.577 0.497159 149.1 0.299456C148.623 0.101754 148.111 -1.19179e-06 147.594 0H53.1777C52.661 -8.93843e-07 52.1495 0.101754 51.6722 0.299456C51.1949 0.497159 50.7612 0.786937 50.3959 1.15225C50.0306 1.51755 49.7408 1.95124 49.5431 2.42853C49.3454 2.90583 49.2436 3.41739 49.2436 3.93402C49.2436 4.97739 49.6581 5.97803 50.3959 6.7158C51.1337 7.45357 52.1343 7.86805 53.1777 7.86805H53.1785L50.9927 78.3406L33.4431 100.802C33.4431 100.802 33.2956 100.802 164.528 100.802C164.528 100.802 164.436 101.029 148.579 78.2782Z'
                fill='#E6E6E6'
              />
              <path
                opacity='0.2'
                d='M104 91C120.016 91 133 89.433 133 87.5C133 85.567 120.016 84 104 84C87.9837 84 75 85.567 75 87.5C75 89.433 87.9837 91 104 91Z'
                fill='#202424'
              />
              <path
                opacity='0.2'
                d='M53.1775 7.86822L148.579 10.6467L148.621 7.69714L53.1775 7.86822Z'
                fill='#202424'
              />
              <path
                d='M104.123 17.3608H99.5361C99.3507 17.3608 99.2004 17.5111 99.2004 17.6965V18.0929C99.2004 18.2783 99.3507 18.4285 99.5361 18.4285H104.123C104.308 18.4285 104.459 18.2783 104.459 18.0929V17.6965C104.459 17.5111 104.308 17.3608 104.123 17.3608Z'
                fill='#E6E8EC'
              />
              <path
                d='M105.859 18.5004C106.193 18.5004 106.465 18.2292 106.465 17.8948C106.465 17.5603 106.193 17.2892 105.859 17.2892C105.525 17.2892 105.253 17.5603 105.253 17.8948C105.253 18.2292 105.525 18.5004 105.859 18.5004Z'
                fill='#E6E8EC'
              />
              <path d='M116.619 61.4144H89.1558V64.9794H116.619V61.4144Z' fill='#E6E6E6' />
              <path d='M116.619 67.7521H89.1558V71.317H116.619V67.7521Z' fill='#E6E6E6' />
              <path d='M116.619 74.0897H89.1558V77.6547H116.619V74.0897Z' fill='#E6E6E6' />
              <path
                d='M51.5335 10.4791L37.4498 10.5666C37.4498 10.5666 14.2685 22.9008 30.5391 48.4439C30.5391 48.4439 37.1873 46.8693 44.5354 52.2928C44.5354 52.2928 48.8217 11.4414 51.5335 10.4791Z'
                fill='#50A398'
              />
              <path
                opacity='0.2'
                d='M47.344 10.6071C44.8208 21.1467 41.9684 48.3306 41.9684 48.3306C35.4035 43.485 29.4014 44.225 28.1958 44.4379C28.9069 45.8459 29.689 47.217 30.5391 48.5458C30.5391 48.5458 37.1873 46.9712 44.5353 52.3947C44.5353 52.3947 48.8216 11.5433 51.5334 10.5811L47.344 10.6071Z'
                fill='#202424'
              />
              <path
                d='M30.4518 23.688L29.2271 29.6365L13.8365 26.839L13.8313 26.8372L13.4814 19.8391H16.4557L30.4518 23.688Z'
                fill='#202424'
              />
              <path
                opacity='0.2'
                d='M16.2072 19.8391L13.8365 26.839L13.8313 26.8372L13.4814 19.8391H16.2072Z'
                fill='#202424'
              />
              <path
                d='M29.9268 30.3363C30.893 30.3363 31.6763 28.5347 31.6763 26.3124C31.6763 24.09 30.893 22.2885 29.9268 22.2885C28.9605 22.2885 28.1772 24.09 28.1772 26.3124C28.1772 28.5347 28.9605 30.3363 29.9268 30.3363Z'
                fill='#202424'
              />
              <path
                d='M20.8292 111.349C20.759 111.349 20.6894 111.335 20.6246 111.309C20.5597 111.282 20.5007 111.242 20.4511 111.193C20.4014 111.143 20.362 111.084 20.3351 111.019C20.3083 110.954 20.2944 110.885 20.2944 110.814V26.3123C20.2944 26.1705 20.3508 26.0345 20.4511 25.9342C20.5513 25.8339 20.6874 25.7776 20.8292 25.7776C20.971 25.7776 21.107 25.8339 21.2073 25.9342C21.3076 26.0345 21.364 26.1705 21.364 26.3123V110.814C21.364 110.885 21.3501 110.954 21.3233 111.019C21.2964 111.084 21.257 111.143 21.2073 111.193C21.1577 111.242 21.0987 111.282 21.0339 111.309C20.969 111.335 20.8994 111.349 20.8292 111.349Z'
                fill='#202424'
              />
              <path
                d='M28.1768 126.22C28.0529 126.22 27.9328 126.177 27.8372 126.098C27.7416 126.019 27.6763 125.91 27.6526 125.788L20.3046 88.1186C20.2808 87.9969 20.3001 87.8708 20.3591 87.7618C20.4181 87.6528 20.5131 87.5676 20.6279 87.5209C20.7427 87.4742 20.8701 87.4688 20.9885 87.5056C21.1068 87.5425 21.2087 87.6193 21.2766 87.7229L40.6963 117.344C40.7741 117.463 40.8016 117.608 40.7727 117.747C40.7438 117.885 40.661 118.007 40.5424 118.085C40.4238 118.163 40.2791 118.19 40.1402 118.161C40.0014 118.132 39.8796 118.05 39.8019 117.931L21.8758 90.5878L28.7023 125.583C28.7157 125.652 28.7155 125.723 28.7015 125.792C28.6876 125.861 28.6602 125.926 28.621 125.984C28.5817 126.043 28.5314 126.093 28.4729 126.131C28.4143 126.17 28.3487 126.197 28.2798 126.211C28.2458 126.217 28.2114 126.22 28.1768 126.22Z'
                fill='#202424'
              />
              <path
                d='M0.534152 119.572C0.437776 119.572 0.343221 119.546 0.260488 119.496C0.177754 119.447 0.109918 119.376 0.0641504 119.291C0.0183826 119.206 -0.0036157 119.111 0.000484187 119.015C0.00458407 118.918 0.0346275 118.825 0.0874394 118.744L20.382 87.7777C20.4598 87.6594 20.5814 87.5767 20.7201 87.548C20.8588 87.5192 21.0033 87.5467 21.1217 87.6243C21.2402 87.702 21.323 87.8235 21.352 87.9621C21.381 88.1007 21.3539 88.2452 21.2764 88.3638L0.98191 119.33C0.933265 119.405 0.866842 119.466 0.788658 119.508C0.710474 119.55 0.622998 119.572 0.534152 119.572Z'
                fill='#202424'
              />
              <path
                d='M148.466 10.4791L162.55 10.5666C162.55 10.5666 185.731 22.9008 169.461 48.4439C169.461 48.4439 162.812 46.8693 155.464 52.2928C155.464 52.2928 151.178 11.4414 148.466 10.4791Z'
                fill='#50A398'
              />
              <path
                opacity='0.2'
                d='M152.656 10.6071C155.179 21.1467 158.031 48.3306 158.031 48.3306C164.596 43.485 170.598 44.225 171.804 44.4379C171.093 45.8459 170.311 47.217 169.461 48.5458C169.461 48.5458 162.812 46.9712 155.464 52.3947C155.464 52.3947 151.178 11.5433 148.466 10.5811L152.656 10.6071Z'
                fill='#202424'
              />
              <path
                d='M169.548 23.688L170.773 29.6365L186.164 26.839L186.169 26.8372L186.519 19.8391H183.545L169.548 23.688Z'
                fill='#202424'
              />
              <path
                opacity='0.2'
                d='M183.793 19.8391L186.164 26.839L186.169 26.8372L186.519 19.8391H183.793Z'
                fill='#202424'
              />
              <path
                d='M170.073 30.3363C171.039 30.3363 171.823 28.5347 171.823 26.3124C171.823 24.09 171.039 22.2885 170.073 22.2885C169.107 22.2885 168.323 24.09 168.323 26.3124C168.323 28.5347 169.107 30.3363 170.073 30.3363Z'
                fill='#202424'
              />
              <path
                d='M179.171 111.349C179.101 111.349 179.031 111.335 178.966 111.309C178.901 111.282 178.842 111.242 178.793 111.193C178.743 111.143 178.704 111.084 178.677 111.019C178.65 110.954 178.636 110.885 178.636 110.814V26.3123C178.636 26.1705 178.692 26.0345 178.793 25.9342C178.893 25.8339 179.029 25.7776 179.171 25.7776C179.313 25.7776 179.449 25.8339 179.549 25.9342C179.649 26.0345 179.706 26.1705 179.706 26.3123V110.814C179.706 110.885 179.692 110.954 179.665 111.019C179.638 111.084 179.599 111.143 179.549 111.193C179.499 111.242 179.44 111.282 179.375 111.309C179.311 111.335 179.241 111.349 179.171 111.349Z'
                fill='#202424'
              />
              <path
                d='M171.823 126.22C171.789 126.22 171.754 126.217 171.72 126.211C171.581 126.183 171.458 126.102 171.379 125.984C171.3 125.867 171.271 125.722 171.298 125.583L178.124 90.5876L160.198 117.931C160.16 117.99 160.11 118.04 160.052 118.08C159.994 118.119 159.929 118.147 159.86 118.161C159.791 118.176 159.72 118.176 159.651 118.163C159.582 118.15 159.516 118.123 159.458 118.085C159.399 118.046 159.348 117.997 159.309 117.939C159.269 117.881 159.242 117.815 159.227 117.747C159.213 117.678 159.212 117.607 159.225 117.538C159.239 117.469 159.265 117.403 159.304 117.344L178.723 87.7229C178.791 87.6193 178.893 87.5425 179.012 87.5056C179.13 87.4688 179.257 87.4742 179.372 87.5209C179.487 87.5676 179.582 87.6528 179.641 87.7618C179.7 87.8708 179.719 87.9969 179.696 88.1185L172.348 125.788C172.324 125.91 172.259 126.019 172.163 126.098C172.067 126.177 171.947 126.22 171.823 126.22Z'
                fill='#202424'
              />
              <path
                d='M199.466 119.572C199.377 119.572 199.289 119.55 199.211 119.508C199.133 119.466 199.067 119.405 199.018 119.33L178.723 88.3638C178.646 88.2452 178.619 88.1007 178.648 87.9621C178.677 87.8235 178.76 87.702 178.878 87.6243C178.997 87.5467 179.141 87.5192 179.28 87.548C179.418 87.5767 179.54 87.6594 179.618 87.7777L199.912 118.744C199.965 118.825 199.995 118.918 199.999 119.015C200.003 119.111 199.981 119.206 199.936 119.291C199.89 119.376 199.822 119.447 199.739 119.496C199.657 119.546 199.562 119.572 199.466 119.572Z'
                fill='#202424'
              />
              <path
                d='M87.8111 74.257L91.6426 73.1903C91.6426 73.1903 93.1433 72.8907 92.2263 69.9596C91.3093 67.0286 89.4906 67.8489 89.4906 67.8489L86.0917 68.8364L84.7498 69.2508L86.6379 71.8997L86.7961 73.6652L87.8111 74.257Z'
                fill='#202424'
              />
              <path
                d='M80.4074 66.5127L79.2053 71.3145L85.3646 77.2839L85.8941 77.4166L87.3067 77.0198L88.509 72.2182L87.2913 70.9377L88.536 70.6116L85.8799 68.0331L84.4938 68.3239L83.3821 67.2202L84.1324 66.9202L84.2032 66.6377L83.2943 65.9224L80.4074 66.5127Z'
                fill='#202424'
              />
              <path
                d='M86.1784 73.2855L85.3827 76.4631L81.7117 72.9926L82.631 69.7709L86.1784 73.2855Z'
                fill='#50A398'
              />
              <path
                d='M80.4073 66.5127L80.2388 67.1861L86.3636 73.1444L86.8931 73.2769L88.2617 72.9066L88.337 72.905L88.509 72.2182L87.2913 70.9377L88.536 70.6116L85.8798 68.0331L84.4938 68.3239L83.382 67.2202L84.1324 66.9202L84.2031 66.6377L83.2942 65.9224L80.4073 66.5127Z'
                stroke='#202424'
                strokemiterlimitterlimitlimit='10'
              />
              <path
                d='M83.731 66.5757C83.7774 66.3904 83.5937 66.1849 83.3207 66.1165C83.0477 66.0482 82.7888 66.1429 82.7424 66.3282C82.696 66.5134 82.8798 66.719 83.1527 66.7873C83.4257 66.8557 83.6846 66.7609 83.731 66.5757Z'
                fill='#50A398'
              />
              <path
                d='M87.6221 71.7147C87.6685 71.5295 87.4848 71.3239 87.2118 71.2555C86.9388 71.1872 86.6799 71.2819 86.6335 71.4672C86.5872 71.6524 86.7709 71.858 87.0439 71.9264C87.3169 71.9947 87.5758 71.9 87.6221 71.7147Z'
                fill='#50A398'
              />
              <path
                d='M90.3568 80.2388L89.5212 77.3979C89.5212 77.3979 91.6936 72.2175 89.187 71.7162C86.6803 71.2148 86.5132 76.5624 86.5132 76.5624L87.5159 79.7375L90.3568 80.2388Z'
                fill='#FFB6B6'
              />
              <path
                d='M74.8434 74.7151C78.5096 74.7151 81.4815 71.7431 81.4815 68.077C81.4815 64.4108 78.5096 61.4388 74.8434 61.4388C71.1773 61.4388 68.2053 64.4108 68.2053 68.077C68.2053 71.7431 71.1773 74.7151 74.8434 74.7151Z'
                fill='#FFB6B6'
              />
              <path
                d='M76.9912 72.7626C76.9912 72.7626 77.3816 77.6436 78.3578 78.034C79.334 78.4245 67.8149 81.1579 67.8149 81.1579L70.7435 77.8388C70.7435 77.8388 71.3292 72.7626 69.9626 71.5912L76.9912 72.7626Z'
                fill='#FFB6B6'
              />
              <path
                d='M86.3459 78.7347L89.0197 78.4005C89.0197 78.4005 92.1948 80.0716 92.1948 82.2441C92.1948 84.4165 94.0331 87.9258 94.0331 87.9258C94.0331 87.9258 95.3699 92.6049 92.3619 92.9392C89.3539 93.2734 86.6802 87.9258 86.6802 87.9258L86.3459 78.7347Z'
                fill='#ADFAF2'
              />
              <path
                d='M87.8499 105.305C87.8499 105.305 93.6988 99.7907 95.3699 100.125C97.041 100.459 100.383 100.961 100.383 103.467C100.383 105.974 94.8685 111.823 94.8685 111.823C94.8685 111.823 90.6908 121.014 86.6801 121.348C84.0418 121.587 81.4194 121.978 78.8259 122.518C78.8259 122.518 80.6641 129.202 79.6615 131.04C78.6588 132.879 74.8153 135.051 74.481 134.884C74.1468 134.717 69.4677 130.372 67.4624 124.189C65.4571 118.006 64.1202 113.327 65.9584 112.992C67.7966 112.658 87.8499 105.305 87.8499 105.305Z'
                fill='#202424'
              />
              <path
                d='M99.8821 102.966C99.8821 102.966 101.052 102.799 100.885 105.639C100.718 108.48 100.216 118.674 99.3808 120.68C98.5452 122.685 98.3781 125.024 98.7123 125.86C99.0466 126.695 99.715 128.032 98.5452 128.199C97.3754 128.367 92.5292 127.698 92.0279 127.531C91.5265 127.364 91.0252 124.523 91.3594 124.523C91.6937 124.523 92.5292 124.356 92.195 123.186C91.8608 122.016 91.6937 122.016 92.195 121.682C92.6963 121.348 94.0332 120.847 93.699 120.512C93.3648 120.178 93.0306 112.324 93.0306 112.324L99.8821 102.966Z'
                fill='#202424'
              />
              <path
                d='M98.2108 127.698C98.2108 127.698 101.052 126.863 101.386 128.701C101.386 128.701 101.887 131.542 99.7148 132.544C98.3129 133.207 96.971 133.99 95.7042 134.884C95.7042 134.884 92.1948 136.221 92.0277 134.55C91.8606 132.879 92.6962 126.863 92.6962 126.863L98.2108 127.698Z'
                fill='#202424'
              />
              <path
                d='M79.3273 129.704L73.9798 125.359C73.9798 125.359 74.9825 130.706 73.6456 131.709L73.0349 133.494L74.745 134.893C74.745 134.893 79.9958 134.048 79.3273 129.704Z'
                fill='#202424'
              />
              <path
                d='M71.8073 122.351C71.8073 122.351 75.3166 123.353 74.8153 126.863C74.314 130.372 73.6712 134.185 73.6712 134.185C73.6712 134.185 73.4784 143.574 70.9717 144.242C68.4651 144.911 67.7966 143.574 67.7966 143.574C67.7966 143.574 64.9473 136.237 65.7913 134.048C66.813 131.4 68.2852 128.425 67.7966 127.531C67.3159 126.651 68.1309 121.849 71.8073 122.351Z'
                fill='#202424'
              />
              <path
                d='M69.8022 76.228C69.8022 76.228 71.1391 76.3951 73.3115 76.7294C75.484 77.0636 76.988 75.3925 78.1577 75.7267C79.3275 76.0609 89.02 78.4005 89.02 78.4005L87.516 89.9311C87.516 89.9311 89.3542 103.3 89.02 105.64C88.6857 107.979 93.0306 107.645 90.524 110.486C88.0173 113.327 83.6724 115.833 74.8155 114.998C65.9586 114.162 64.7889 114.329 64.7889 112.992C64.7889 111.656 66.7942 100.292 66.1258 99.2893C65.4573 98.2867 64.1204 97.1169 64.7889 95.9471C65.4573 94.7773 66.1258 94.1089 65.1231 93.1062C64.1204 92.1036 63.6191 91.1009 63.6191 90.5996C63.6191 90.0982 61.2795 81.9098 61.2795 81.9098C61.2795 81.9098 62.2822 77.8991 63.452 77.732C64.6217 77.5649 65.6244 76.7294 66.46 76.7294C67.2955 76.7294 67.7969 76.8965 68.2982 76.7294L69.8022 76.228Z'
                fill='#ADFAF2'
              />
              <path
                d='M85.8272 65.7485C85.7412 65.3623 85.3204 64.0709 84.4647 63.7559C83.8387 63.5254 82.6739 64.0124 82.0938 63.9316L82.019 63.7191C81.9693 63.3007 81.865 62.8906 81.7088 62.4993C81.4023 61.7169 80.941 61.0043 80.3525 60.4045C79.764 59.8046 79.0604 59.3297 78.284 59.0083C76.3732 58.2095 73.3508 57.549 70.7286 59.6421C67.1268 62.517 67.7615 66.819 68.0232 68.0126C68.0668 68.2117 68.1042 68.4121 68.1354 68.6137C68.2968 69.6651 68.762 69.7905 68.762 69.7905C68.762 69.7905 68.7519 69.7602 68.7356 69.7049L71.9888 70.0216L73.7476 69.7214C73.7771 69.8934 73.7885 69.9951 73.7885 69.9951C78.921 69.2106 81.9077 66.5072 82.3927 66.0384C82.8191 66.3211 83.6132 66.7827 84.3833 66.8839C85.5112 67.0322 85.9132 66.1348 85.8272 65.7485ZM72.6028 68.3332L68.4698 68.1828C68.4112 67.0547 68.7025 65.7355 70.3638 65.6682C71.9656 65.6033 72.8398 66.9056 73.3032 68.0979L72.6028 68.3332Z'
                fill='#202424'
              />
              <path
                d='M73.683 58.6869C74.081 58.619 74.3829 58.4418 74.3572 58.2912C74.3315 58.1406 73.9879 58.0736 73.5899 58.1415C73.1918 58.2095 72.89 58.3866 72.9157 58.5372C72.9414 58.6879 73.2849 58.7549 73.683 58.6869Z'
                fill='#202424'
              />
              <path
                d='M111.187 49.2049L111.838 56.9602C111.838 56.9602 112.224 62.0029 110.929 62.2604C109.634 62.5178 110.02 57.7344 110.02 57.7344L110.406 49.201L111.187 49.2049Z'
                fill='#FFB296'
              />
              <path
                opacity='0.1'
                d='M111.838 50.2364L110.929 47.0033C110.929 47.0033 110.157 40.539 110.543 40.0222C110.929 39.5054 110.414 36.0149 110.414 36.0149L108.791 38.2126L108.333 42.7443L109.63 50.6207C109.63 50.6207 111.058 50.8858 111.838 50.2364Z'
                fill='#202424'
              />
              <path
                d='M104.667 37.1849C108.184 37.1849 111.035 33.5726 111.035 29.1165C111.035 24.6605 108.184 21.0481 104.667 21.0481C101.149 21.0481 98.2981 24.6605 98.2981 29.1165C98.2981 33.5726 101.149 37.1849 104.667 37.1849Z'
                fill='#632652'
              />
              <path
                d='M111.838 49.9811L110.929 46.7479C110.929 46.7479 110.157 40.2836 110.543 39.7668C110.929 39.2501 110.414 35.7595 110.414 35.7595L108.791 37.9572L108.333 42.4813L109.632 50.3672C109.632 50.3672 111.058 50.6208 111.838 49.9811Z'
                fill='#D86369'
              />
              <path
                d='M102.667 80.6065C102.667 80.6065 101.222 85.1555 100.836 85.5859C100.45 86.0162 100.294 88.0141 101.523 88.0832C102.752 88.1524 103.331 88.0986 104.19 85.9336C105.049 83.7685 105.927 81.1943 105.803 80.4509C105.68 79.7074 102.667 80.6065 102.667 80.6065Z'
                fill='#D86369'
              />
              <path
                opacity='0.05'
                d='M102.667 80.6065C102.667 80.6065 101.253 84.9538 100.836 85.5859C100.518 86.07 100.294 88.0141 101.523 88.0832C102.752 88.1524 103.331 88.0986 104.19 85.9336C105.049 83.7685 105.927 81.1943 105.803 80.4509C105.68 79.7074 102.667 80.6065 102.667 80.6065Z'
                fill='#202424'
              />
              <path d='M107.812 25.5453H100.539V33.8193H107.812V25.5453Z' fill='#632652' />
              <path
                opacity='0.1'
                d='M107.812 25.5453H100.539V33.8193H107.812V25.5453Z'
                fill='#202424'
              />
              <path
                d='M102.351 28.3883C102.351 28.3883 103.123 31.6214 102.351 32.3956C102.351 32.3956 100.792 33.3004 100.402 33.043C100.012 32.7856 98.4526 33.5598 98.4526 33.5598L100.921 38.9906L106.116 39.3748L108.843 38.6064V35.6364L108.714 33.8268C108.714 33.8268 105.597 33.8268 105.726 32.6626C105.855 31.4985 106.112 28.396 106.112 28.396L102.351 28.3883Z'
                fill='#FFB296'
              />
              <path
                d='M108.461 66.7884L108.052 70.1483C107.978 70.7514 107.862 71.3484 107.702 71.9349L104.564 83.4612L102.486 81.3999L104.823 71.3144L105.473 65.4955L108.461 66.7884Z'
                fill='#FFB296'
              />
              <path
                opacity='0.05'
                d='M108.461 66.7884L108.052 70.1483C107.978 70.7514 107.862 71.3484 107.702 71.9349L104.564 83.4612L102.486 81.3999L104.823 71.3144L105.473 65.4955L108.461 66.7884Z'
                fill='#202424'
              />
              <path
                d='M100.539 67.1763L101.193 71.831C101.193 71.831 101.454 77.3905 102.231 78.1705L104.829 87.4799L106.907 86.7115C106.907 86.7115 105.479 79.471 105.738 78.9543C105.996 78.4375 105.477 74.0402 105.477 74.0402V71.0549C105.477 71.0549 105.477 68.0811 105.348 67.1763C105.219 66.2715 100.539 67.1763 100.539 67.1763Z'
                fill='#FFB296'
              />
              <path
                d='M104.435 86.4406C104.435 86.4406 104.89 90.9013 104.564 91.7408C104.356 92.2806 104.95 94.1978 106.124 93.8098C107.297 93.4217 107.812 93.1624 107.812 90.836C107.812 88.5096 107.098 85.8586 106.708 85.2112C106.319 84.5638 104.435 86.4406 104.435 86.4406Z'
                fill='#D86369'
              />
              <path
                opacity='0.1'
                d='M101.673 37.4654C101.145 37.4884 100.238 36.9371 99.678 33.337C99.678 33.337 96.901 33.4331 96.3818 34.8547C95.8627 36.2762 90.0134 44.2928 90.0134 44.2928V47.5259C90.0134 47.5259 93.5199 48.2943 95.0792 46.3618C95.0792 46.3618 92.3524 45.845 94.1703 43.388L97.5475 39.6381C97.5475 39.6381 100.274 41.5783 99.4966 46.8785C99.4966 46.8785 96.7697 54.7645 97.9373 60.1953C97.9373 60.1953 100.406 66.4022 99.7552 68.7286C99.7552 68.7286 106.249 70.0214 109.366 67.8238C109.366 67.8238 109.105 62.6523 110.535 60.1953C111.965 57.7382 109.366 46.6192 109.366 46.6192C109.366 46.6192 107.772 43.4879 108.812 42.3237C109.852 41.1596 109.005 38.4816 109.005 38.4816C109.005 38.4816 111.573 34.8527 108.717 34.0786C108.717 34.0786 109.103 38.2165 107.289 37.6978L104.041 37.4558C103.253 37.4129 102.462 37.4161 101.673 37.4654Z'
                fill='#202424'
              />
              <path
                d='M101.673 37.206C101.145 37.229 100.238 36.6777 99.678 33.0776C99.678 33.0776 96.901 33.1737 96.3818 34.5953C95.8627 36.0168 90.0134 44.0334 90.0134 44.0334V47.2665C90.0134 47.2665 93.5199 48.035 95.0792 46.1024C95.0792 46.1024 92.3524 45.5856 94.1703 43.1286L97.5475 39.3787C97.5475 39.3787 100.274 41.319 99.4966 46.6191C99.4966 46.6191 96.7697 54.5051 97.9373 59.9359C97.9373 59.9359 100.406 66.1428 99.7552 68.4692C99.7552 68.4692 106.249 69.7621 109.366 67.5644C109.366 67.5644 109.105 62.3929 110.535 59.9359C111.965 57.4788 109.366 46.3598 109.366 46.3598C109.366 46.3598 108.196 43.386 109.236 42.2218C110.277 41.0577 109.366 38.2145 109.366 38.2145L110.535 38.4739C110.535 38.4739 111.573 34.5953 108.717 33.8192C108.717 33.8192 109.103 37.9571 107.289 37.4384L104.041 37.1964C103.253 37.1535 102.462 37.1567 101.673 37.206Z'
                fill='#D86369'
              />
              <path
                d='M94.0526 46.1024L97.4201 49.8522C97.4201 49.8522 98.6745 49.6601 98.8907 48.6516C98.8907 48.6516 102.874 49.0761 101.965 50.4977C101.056 51.9193 97.4182 52.438 97.4182 52.438L90.6638 46.0985L94.0526 46.1024Z'
                fill='#FFB296'
              />
              <path
                opacity='0.1'
                d='M105.855 31.6311C105.983 30.2864 106.124 28.5844 106.124 28.5844H102.351C102.542 29.4046 102.658 30.2405 102.696 31.0817C103.153 31.3899 103.67 31.5983 104.214 31.693C104.757 31.7877 105.315 31.7666 105.85 31.6311H105.855Z'
                fill='#202424'
              />
              <path
                d='M104.889 31.5562C107.041 31.5562 108.785 29.8196 108.785 27.6776C108.785 25.5355 107.041 23.799 104.889 23.799C102.737 23.799 100.992 25.5355 100.992 27.6776C100.992 29.8196 102.737 31.5562 104.889 31.5562Z'
                fill='#FFB296'
              />
              <path
                opacity='0.1'
                d='M90.8627 46.9419C90.8627 46.9419 92.8948 48.3154 94.8651 46.5865L93.3213 45.3839H90.7314L90.8627 46.9419Z'
                fill='#202424'
              />
              <path
                d='M90.7314 46.8131C90.7314 46.8131 92.7654 48.1847 94.7357 46.4654L93.1919 45.2628H90.6021L90.7314 46.8131Z'
                fill='#D86369'
              />
              <path
                opacity='0.1'
                d='M109.435 39.9608C109.584 41.2084 109.537 42.4714 109.298 43.7049C108.912 45.9026 108.949 48.7477 109.512 49.2645'
                fill='#202424'
              />
              <path
                opacity='0.1'
                d='M99.842 26.0006C99.842 26.0006 101 27.3453 104.088 26.8362C105.947 26.5352 107.847 26.5919 109.684 27.0034L108.912 24.8326L106.789 23.1613H103.702L101 24.8326L99.842 26.0006Z'
                fill='#202424'
              />
              <path
                d='M99.842 25.8084C99.842 25.8084 101 27.1532 104.088 26.6441C105.947 26.3431 107.847 26.3998 109.684 26.8112L108.912 24.6404L106.789 22.9691H103.702L101 24.6404L99.842 25.8084Z'
                fill='#632652'
              />
            </g>
            <defs>
              <clipPath id='clip0_59_94'>
                <rect width='200' height='144.429' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <p className='main_page_about_section_content_card_text__2'>
            Связаться и заказать съемку или фотосессию
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutBlock;
