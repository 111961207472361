import React from "react";
import "./styles.css";

const Rules = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ margin: "20px 0" }} className="additional_wrapper rules">
      <>
        <>
          <p>
            <strong>Правила. VK16322</strong>
          </p>
          <p>
            <br />
            Photodel.ru — сообщество пользователей связанных с фотографией,
            предназначено для обмена опытом, знаниями, информацией и новостями
            по тематике сайта. <br />
            Регистрируясь на сайте photodel.ru или используя сервис любым
            способом, вы соглашаетесь и принимаете эти правила.
          </p>
          <p>
            Правила предназначены для поддержания нормальной и человечной
            атмосферы в сообществе.
          </p>
          <p>
            В обсуждениях фотографий и других публикациях на сайте обсуждается
            всё, что имеет отношение к тематике сообщества. Придерживайтесь этих
            правил.
          </p>
          <br />
          <p>
            <strong>Не приветствуется;</strong> <br />- Развитие дискуссии вне
            заданной темы.
            <br />- Личная переписка с участниками дискуссии, неинтересная
            остальным её участникам.
            <br />- Оскорбления участников дискуссии и применение нецензурных
            выражений.
            <br />- Религиозные, национальные и политические споры. <br />-
            Обсуждения действий и бездействий модераторов и администрации.{" "}
            <br />- Ответы на некорректные сообщения и сообщения вне темы.
            <br />- Вступление в словесные перепалки с посетителями сайта,
            которые оскорбляют вас и/или других
            <br />
            участников.
            <br />- Ответы на сообщения, содержащие нецензурные выражения.
            <br />- Публикация личной переписки без согласия другого участника.
            <br />
            <br />
            Если вы хотите обсудить действия модераторов, сообщить об ошибке в
            работе сайта или предложение
            <br />
            по работе сайта — напишите нам (почта).
          </p>
          <p>
            Запоздалая реакция, как правило, связана с отсутствием времени на
            чтение всех ваших сообщений. Лучшее, что вы можете сделать, если вы
            увидели нарушение правил, — сообщить нам. (почта)
          </p>
          <br />
          <p>
            <strong>Регистрация;</strong>
            <br />- Регистрация пользователя на сайте осуществляется бесплатно,
            добровольно и производится по <br />
            адресу photodel.ru
            <br />- Обработка персональных данных пользователя осуществляется в
            соответствии с законодательством <br />
            Российской Федерации и политикой конфиденциальности. <br />-
            Пользователь несет личную ответственность за достоверность и
            соответствие <br />
            законодательству Российской Федерации за любой контент или иную
            информацию, которые он <br />
            загружает на сайт или иным образом доводит до всеобщего сведения.
            <br />- Администрация сайта не несет ответственности за содержание
            передаваемой или получаемой <br />
            информации пользователем и за ущерб, причиненный третьим лицам.
          </p>
          <br />
          <p>
            <strong>При регистрации на сайте запрещается:</strong>
          </p>
          <p>
            - Использование нецензурных слов в имени пользователя.
            <br />- Использование более одного логина одним пользователем.{" "}
            <br />- Использование одного логина более чем одним пользователем.
            <br />- Использовать длинные имена, состоящие из произвольного
            набора символов и случайных <br />
            последовательностей.
            <br />- Использование имён, похожих на ники уже зарегистрированных
            пользователей.
            <br />- Умышленное использование чужих имен.
            <br />- Выдавать себя за другого человека.
            <br />- Размещать на сайте информацию и объекты (включая ссылки на
            них), которые могут нарушать <br />
            законодательство Российской Федерации.
            <br />
            <br />
            <strong>Функция правил модерирования;</strong>
          </p>
          <p>
            Цель правил, модерирование сайта и поддержание здоровой дискуссии в
            сообществе.
          </p>
          <p>
            Сообщения на сайте постмодерируемые, поэтому наличие сообщения,
            нарушающего правила на сайте не является его соответствием правилам
            по умолчанию.
            <br />В случае несоответствия правилам сообщение может быть удалено.{" "}
            <br />
            Необходимость применения или неприменения правил решается
            модератором лично в каждом конкретном случае.
          </p>
          <p>
            Сообщения, связанные смыслом с другими комментариями, но не
            связанные с темой обсуждения могут быть удалены по усмотрению
            модератора.
            <br />
            Мы уважаем чужое мнение, но оставляем за собой право удалять
            сообщения, несущие неверные, неподтвержденные факты.
            <br />- Сообщения, провоцирующие нездоровые дискуссии.
            <br />- Сообщения, содержащие спам и флуд.
            <br />- Сообщения, на умышленно исковерканном языке. <br />-
            Сообщения, вводящие пользователей в заблуждение.
            <br />- Сообщения, содержащие нецензурные слова.
            <br />- Сообщения, содержащие оскорбления участников дискуссии или
            группы участников.
            <br />- Сообщения, провоцирующие национальные, политические и
            религиозные споры.
            <br />- Сообщения, заведомо неинтересные другим участникам
            обсуждения, т. е. личная переписка, не <br />
            содержащая полезной информации для других участников.
            <br />- Сообщения, содержащие преднамеренное искажение русского
            языка. <br />- Сообщения, содержащие личную информацию других людей.
          </p>
          <p>
            <br />
            <strong>Нарушение авторских прав;</strong>
          </p>
          <p>
            - Размещение изображений других авторов без указания автора и
            источника.
            <br />- Сообщения, содержащие информацию с других источников, явным
            образом не разрешающих их <br />
            копирование. Если вы хотите поделиться новостью и/или изображением,
            укажите автора или <br />
            перескажите её своими словами.
            <br />- Ссылки на места расположения нелегально распространяемого
            ПО.
            <br />- Ссылки на страницы, ссылки на которые запрещены их авторами.
          </p>
          <p>
            <br />
            Вместе с некорректными сообщениями могут быть удалены созданные на
            них комментарии. <br />
            Не злоупотребляйте правкой заголовка и описания объекта (изображения
            или текста). <br />
            Недопустимо после получения комментариев заменить оригинальное
            сообщение/описание другим. Чрезмерное злоупотребление данной
            функцией является поводом для блокировки.
          </p>
          <p>
            <br />
            <strong>Механизм выбора модераторов и редакторов;</strong>
            <br />- Активный пользователь может стать модератором и/или
            редактором. <br />- Пользователь может сообщить о своём желании по
            e-mail (почта).
            <br />- Ваше письмо не является фактом назначения. Окончательное
            решение на усмотрение <br />
            администрации.
            <br />- Модератору/редактору будет назначен испытательный срок 1
            месяц.
            <br />- Модератор/редактор может быть снят с должности;
            <br />- По собственному желанию.
            <br />- Если он не посещает сайт в течении месяца и более. <br />-
            При грубом и/или систематическом нарушении правил.
          </p>
          <p>
            <br />
            <strong>Блокировка пользователей;</strong>
            <br />- В случае систематических нарушений пользователь может быть
            заблокирован.
            <br />- При снятии блокировки величина рейтинга может быть понижена
            по решению модератора.
            <br />- При блокировке пользователя обязательно должна быть указана
            причина.
          </p>
          <p>
            В случаях если пользователь опубликовал пароль от личного аккаунта в
            открытом виде или в том виде, который позволит воспользоваться его
            логином третьим лицам, аккаунт блокируется без возможности
            восстановления.
          </p>
          <p>
            Постоянная или временная блокировка является наказанием за нарушение
            правил. Блокировки по желанию пользователей не включаются.
          </p>
          <p>
            На усмотрение модератора, пользователь может быть временно
            заблокирован (1–30 дней). Эта мера применяется к пользователям,
            которым нужно «отдохнуть от общества», после чего их, отдохнувших, и
            воодушевленных будут снова рады видеть на сайте.
          </p>
          <p>
            <br />
            <strong>Изображения;</strong>
            <br />
            Допускаются следующие изображения:
            <br />
            Пользователи имеют возможность размещать созданные ими изображения.
            Допускается размещение <br />
            других изображений. В описании к изображению должно быть указано
            авторство и ссылка на источник.
          </p>
          <p>
            <strong>Запрещено размещение изображений:</strong>
            <br />- Содержащих нецензурные надписи и изображения;
            <br />- Содержащих фрагменты, оскорбляющие участников дискуссии;
            <br />- Провоцирующих национальные и политические споры;
            <br />- Нарушающих copyright автора изображения.
            <br />- Нарушающих законодательство страны, на территории которой
            расположен ресурс (Россия) и страны
            <br />
            проживания пользователя.
            <br />В случае нарушения изображение может быть удалено. В случае
            систематических нарушений <br />
            пользователь может быть заблокирован в соответствии с пунктом
            «Блокировка пользователей».
          </p>
          <p>
            <br />
            <strong>Личная информация о пользователях;</strong>
            <br />
            Модераторы сайта имеют доступ к информации о пользователе,
            недоступной обычным пользователям.
            <br />
            Информация считается личной:
          </p>
          <p>
            - E-mail пользователя, указанный при регистрации;
            <br />- IP-адрес пользователя, географическое местоположение, факт
            наличия нескольких пользователей <br />
            на одном IP;
            <br />- Используемый браузер/операционная система;
            <br />- Хеш пароля пользователя.
            <br />
            Модераторы сайта обязуются не разглашать данные сведения в открытый
            доступ. Данные правила не распространяются на сведения, указанные
            пользователем в открытом доступе (на сайте или в открытой части
            профиля, а так же полученные из других открытых источников
            информации). Во избежание спорных ситуаций по данному правилу
            модераторам рекомендуется указывать источники информации.
          </p>
          <p>
            <br />
            <strong>Администрация сайта оставляет за собой право;</strong>
            <br />- Редактировать, обновлять, усовершенствовать функционал
            сайта.
            <br />- Обновлять существующие правила.
            <br />- Отказать пользователю в регистрации без объяснения причин.
          </p>
        </>
      </>
    </div>
  );
};

export default Rules;
